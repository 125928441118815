.loginAuthSeparator::before {
  background: #71717A;
  margin-right: 4vh;
}

.loginAuthSeparator::after {
  background: #71717A;
  margin-left: 4vh;
}

.loginAuthSeparator::before,
.loginAuthSeparator::after {
  content: "";
  display: block;
  height: 0.1px;
  min-width: 30%;
}

.loginAuthSeparator {
  color: #000;

  display: flex;
  justify-content: center;
  align-items: center;
}