@tailwind base;
@tailwind components;
@tailwind utilities;

// PATHS
$font-path: "./assets/fonts" !default;

// FUENTES
@font-face {
  font-family: "Poppins";
  src: local("Poppins"), url("#{$font-path}/Poppins-Regular.ttf");
}

@font-face {
  font-family: "PaytoneOne";
  src: local("PaytoneOne"), url("#{$font-path}/paytone-one.regular.ttf");
}

// ELEMENTOS
h1,
h2,
h3,
h4,
strong {
  color: hsl(var(--nextui-default-600)) !important;
}

a {
  color: inherit !important;
}

// BLOCKNOTE
.bn-editor:focus {
  outline: none;
}

.prose :where(p):not(:where([class~="not-prose"], [class~="not-prose"] *)) {
  margin: 0;
}
